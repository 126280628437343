const logApiError = async (message, response) => {
    const err = await response
        .text()
        .then((text) => {
            try {
                return JSON.parse(text);
            } catch {
                return text;
            }
        })
        .catch(() => 'an unknown error');

    console.error(message, err);
};

const PLAYLIST_API_BASE_URL = 'https://playlist.stream.schibsted.media/vgtv';

const getPlaylist = async (playlistId) => {
    const response = await fetch(
        `${PLAYLIST_API_BASE_URL}/${playlistId}?additional=metadata`,
    );

    if (!response.ok) {
        await logApiError('failed to fetch assets', response);

        return [];
    }

    const { resolved } = await response.json();

    return resolved;
};

const getContent = async (playlistId) => {
    const response = await fetch(
        `${process.env.USER_SEGMENTATION_API_URL}/v1/vgtv-stories?playlistId=${playlistId}&limit=16`,
        {
            credentials: 'include',
        },
    );

    if (!response.ok) {
        await logApiError('failed to fetch recommended content', response);

        return { assets: [], params: {} };
    }

    const { sdrns: data, strategyInfo } = await response.json();

    const ids = data.map((id) => Number(id.split(':')[2]));

    let assets = await getPlaylist(playlistId);

    if (strategyInfo.testOrControl === 'test') {
        assets = ids
            .map((id) => assets.find((asset) => asset.id === id))
            .filter(Boolean);
    }

    return {
        assets,
        params: strategyInfo,
    };
};

const PERSONALIZED_PLAYLIST_IDS = [
    'fWydCB4Eoi1zcJ4nVqwJJ50Y', // vgtv stories front
    'akdkW7dOda1gDEzdyPVKVev9', // usa-valg
];

export const getData = async ({ playlistId, row }) => {
    if (!PERSONALIZED_PLAYLIST_IDS.includes(playlistId)) {
        return {
            utm: {
                utm_source: 'vgfront',
                utm_content: 'short-videos-widget',
            },
        };
    }

    const { assets, params } = await getContent(playlistId);

    if (assets.length > 3) {
        return {
            items: assets.map((asset, index) => {
                return {
                    asset,
                    playlistId,
                    utm: {
                        utm_source:
                            params.testOrControl == 'test'
                                ? 'vgfront-personalized'
                                : 'vgfront',
                        utm_content: `hovedlopet_row${row}_pos${index + 1}`,
                        utm_medium: 'short-videos-widget',
                        utm_term: [
                            params.service,
                            params.testOrControl,
                            params.strategyName,
                            params.strategyVersion,
                            params.trackingTag,
                        ].join('-'),
                    },
                };
            }),
        };
    }

    const fallback = await getPlaylist(playlistId);

    return {
        items: fallback.map((asset, index) => {
            return {
                asset,
                playlistId,
                utm: {
                    utm_source: 'vgfront',
                    utm_content: `hovedlopet_row${row}_pos${index + 1}`,
                    utm_medium: 'short-videos-widget',
                },
            };
        }),
    };
};
